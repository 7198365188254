import { Application, EventAction, MutationGroup, MutationType, OfferFase, RequestType, Term, YesNo } from "./enums"
import { ProductCode } from "./products"
import { TaskType } from "./tasktypes"

export interface ContactFormSubject {
	value: string
	label: string
	taskTypeRequest: TaskType
}

export interface FileData {
	file: File
	fileBase64: string
	valid: boolean
}

export interface AxonDocument {
	fileName: string
	contents: string
}

export interface MutationPostData {
	subject: string
	policyId: string
	message: string
	listOfDocuments: FilesToUpload | null
	taskType?: TaskType
}

export interface FilesToUpload {
	listOfDocuments: Array<AxonDocument>
}

export interface FormContentTexts {
	messageLabel: string
	placeHolder: string
	send: string
	sendPlus: string
}

export interface PolicyPerson {
	personID?: string
	fullName?: string
	dateOfBirth?: Date
}

export interface InsuredPerson extends PolicyPerson {
	insuredType?: string
	mortalityBase?: string
	insuredDateOfBirth?: Date
}

export enum ClauseCode {
	LYF_ALIMTT = "LYF_ALIMTT",
	KAP_ERFRNT = "KAP_ERFRNT",
	GDHDRK_NW = "GDHDRK_NW",
	GDHDRK = "GDHDRK",
	KAP_BOX1 = "KAP_BOX1",
	KEW = "KEW",
	KAP_Box3 = "KAP_Box3",
	KAP_LYF = "KAP_LYF",
	KAP_pBHW = "KAP_pBHW",
	KAP_BHW_BV = "KAP_BHW_BV",
	KAP_BHW = "KAP_BHW",
	LEVENSLOOP = "LEVENSLOOP",
	LYF_BHW = "LYF_BHW",
	LYF_IB2001 = "LYF_IB2001",
	KAP_LYF_PU = "KAP_LYF_PU",
	LYF_INVKND = "LYF_INVKND",
	LYF_NETTO = "LYF_NETTO",
	ODGRSV_NW = "ODGRSV_NW",
	ODGRSV_FOR = "ODGRSV_FOR",
	PW = "PW",
	STW_IB2001 = "STW_IB2001",
	STKG_WINST = "STKG_WINST",
	LYF_SALDO = "LYF_SALDO",
	KAP_IB_BV = "KAP_IB_BV",
	LYF_UGSZVR = "LYF_UGSZVR",
	VPB = "VPB",
	OW1919 = "OW1919"
}

export interface Clause {
	code: ClauseCode
	nameAxon: string
	title: string
}

export const clauses: Array<Clause> = [
	{
		code: ClauseCode.LYF_ALIMTT,
		nameAxon: "Alimentatie lijfrente",
		title: "Alimentatie lijfrente"
	},
	{
		code: ClauseCode.KAP_ERFRNT,
		nameAxon: "Erfrente",
		title: "Erfrente"
	},
	{
		code: ClauseCode.GDHDRK_NW,
		nameAxon: "Gouden handdruk (nieuw)",
		title: "Gouden handdrukverzekering"
	},
	{
		code: ClauseCode.GDHDRK,
		nameAxon: "Gouden handdruk (oud)",
		title: "Gouden handdrukverzekering"
	},
	{
		code: ClauseCode.KAP_BOX1,
		nameAxon: "Kapitaalverzekering (box1)",
		title: "Kapitaalverzekering zonder vrijstelling op 31-12-2000"
	},
	{
		code: ClauseCode.KEW,
		nameAxon: "Kapitaalverzekering Eigen Woning (KEW)",
		title: "Kapitaalverzekering eigen woning (KEW)"
	},
	{
		code: ClauseCode.KAP_Box3,
		nameAxon: "Kapitaalverzekering IB2001 (box 3)",
		title: "Kapitaalverzekering box 3 - gesloten na 31-12-2000"
	},
	{
		code: ClauseCode.KAP_LYF,
		nameAxon: "Kapitaalverzekering met lijfrenteclausule",
		title: "Lijfrenteverzekering oud regime"
	},
	{
		code: ClauseCode.KAP_pBHW,
		nameAxon: "Kapitaalverzekering pre-BHW",
		title: "Kapitaalverzekering oud regime - gesloten voor 1-1-1992"
	},
	{
		code: ClauseCode.KAP_BHW_BV,
		nameAxon: "Kapitaalverzekering regime BHW met BV",
		title: "Kapitaalverzekering brede herwaardering - gesloten tussen 1-1-1992 en 15-9-1999"
	},
	{
		code: ClauseCode.KAP_BHW,
		nameAxon: "Kapitaalverzekering regime BHW zonder BV",
		title: "Kapitaalverzekering brede herwaardering - gesloten tussen 15-9-1999 en 31-12-2000"
	},
	{
		code: ClauseCode.LEVENSLOOP,
		nameAxon: "Levensloop",
		title: "Levensloop"
	},
	{
		code: ClauseCode.LYF_BHW,
		nameAxon: "Lijfrente BHW (box 1)",
		title: "Lijfrenteverzekering nieuw regime"
	},
	{
		code: ClauseCode.LYF_IB2001,
		nameAxon: "Lijfrente IB2001 (box 1)",
		title: "Lijfrenteverzekering nieuw regime"
	},
	{
		code: ClauseCode.KAP_LYF_PU,
		nameAxon: "Lijfrente IB2001 (box 3)",
		title: "Lijfrenteverzekering box 3"
	},
	{
		code: ClauseCode.LYF_INVKND,
		nameAxon: "Lijfrente invalide kind",
		title: "Lijfrenteverzekering nieuw regime"
	},
	{
		code: ClauseCode.LYF_NETTO,
		nameAxon: "NettoLijfrente IB2001 (box 3)",
		title: "Nettolijfrente"
	},
	{
		code: ClauseCode.ODGRSV_NW,
		nameAxon: "Oudedagsreserve FOR (IB 2001)",
		title: "Lijfrenteverzekering nieuw regime"
	},
	{
		code: ClauseCode.ODGRSV_FOR,
		nameAxon: "Oudedagsreserve FOR (oud)",
		title: "Lijfrenteverzekering oud regime"
	},
	{
		code: ClauseCode.PW,
		nameAxon: "Pensioenwet (PW)",
		title: "Pensioenverzekering"
	},
	{
		code: ClauseCode.STW_IB2001,
		nameAxon: "Stakingswinst (IB2001)",
		title: "Lijfrenteverzekering nieuw regime"
	},
	{
		code: ClauseCode.STKG_WINST,
		nameAxon: "Stakingswinst (oud)",
		title: "Lijfrenteverzekering oud regime"
	},
	{
		code: ClauseCode.LYF_SALDO,
		nameAxon: "Saldolijfrente",
		title: "Saldolijfrente"
	},
	{
		code: ClauseCode.KAP_IB_BV,
		nameAxon: "Kapitaalverzekering IB2001 (box 3) met BV",
		title: "Kapitaalverzekering IB2001 (box 3) met BV"
	},
	{
		code: ClauseCode.LYF_UGSZVR,
		nameAxon: "Uitgestelde zuivere lijfrente",
		title: "Uitgestelde zuivere lijfrente"
	},
	{
		code: ClauseCode.VPB,
		nameAxon: "Vennootschapsbelasting",
		title: "Vennootschapsbelasting"
	},
	{
		code: ClauseCode.OW1919,
		nameAxon: "Voortzetting ouderdomswet 1919",
		title: "Voortzetting ouderdomswet 1919"
	}
]

export enum PolicyStatus {
	ACTIVE = "Actief",
	EXPIRED = "Beëindigd",
	FUTURE = "Toekomstig"
}

export interface InsuredData {
	insured: InsuredPerson
	coInsured: InsuredPerson
	multipleInsured: boolean
	premiumIncreaseNotAllowed?: boolean
	extensionNotAllowed?: boolean
	hasWisselDatumClause?: boolean
	sourceSystem?: string
}

export interface PolicyData extends InsuredData {
	policyIdentifier: string
	productCode?: ProductCode
	productName: string
	status?: PolicyStatus
	clause?: Clause | null
	policyVersionExtId: string
	policyEnddate?: Date
	policyHolder: PolicyPerson
	coPolicyHolder: PolicyPerson
	coPolicyHolderExists: boolean
	isPolicyHolderLoggedIn: boolean
	redeemable: boolean
	intermediary?: IntermediaryContactDetails
	currentPremiumFrequency?: Term
	currentPremiumTotal?: number
	multiplePremiums: boolean
	isRecentlyExpiredWithAllowedProduct: boolean
	premiumIncreaseNotAllowed?: boolean
	extensionNotAllowed?: boolean
	hasWisselDatumClause?: boolean
	sourceSystem?: string
	costAndProvisionVariant?: string
	hasPackageAccrualPolicyToRedeem: boolean
	maturityBenefit?: number
	calculatedMaturityBenefit?: number
}

export interface IntermediaryContactDetails {
	name: string
	emailAddress?: string
	phoneNumber?: string
}

export interface CustomerContactDetails {
	emailAddress1: string | null
	telephoneNumberMobile: string | null
}

export interface PaperForm {
	title: string
	url: string
}

export interface FormTexts {
	title?: string
	intro?: string
	introList?: Array<string>
	step1?: string
	step2?: string
	step3?: string
	paperForm?: PaperForm
	paperFormList?: Array<DownloadForm>
	paperFormUploadOnly?: boolean
}

// Don't change the HelpText id's. They are defined by Axon backend.
export interface HelpText {
	id: "formulierStopzetten" | "uitkeringsformulier" | "verklaringInLeven"
		| "kopieIDBewijs" | "akkoordGeldverstrekker" | "kopieBankafschriftVzn1" | "UitkeringsformulierBegunstigde"
	text: string
	infoComponent?: "InformationModalBankStatement"
}

export enum DocumentType {
	KOPIE_ID_BEWIJS = "kopie identiteitsbewijs",
	KOPIE_BANKAFSCHRIFT = "kopie bankafschrift"
}

export interface RequiredDocument {
	documentTitle: string
	documentTitleMultiple?: string
	documentCondition?: "ONEPOLICYHOLDERONLY" | "TWOPOLICYHOLDERS" | "POLICYHOLDER_LOGGEDIN" | "COPOLICYHOLDER_LOGGEDIN"
	documentEnvironment?: Application
	documentType?: DocumentType
	helpTextId?: HelpText["id"]
}

export interface ApplicationMutationData extends FormTexts {
	[OfferFase.REQUEST]?: FormTexts
	[OfferFase.APPROVED]?: FormTexts
	active: boolean
}

export interface Mutation {
	mutationType: MutationType
	mutationGroup: MutationGroup
	requestType: RequestType
	excludeForProducts?: Array<ProductCode>
	excludeForCountries?: Array<string>
	subject: string
	title: string
	path: string
	taskTypeRequest?: TaskType
	taskTypeApproved?: TaskType
	requiredDocuments?: Array<RequiredDocument>
	[Application.SELFSERVICE]: ApplicationMutationData
	[Application.EXTRANET]: ApplicationMutationData
}

export interface OptionModel {
	value: any
	label: string
}

export function toOptionModel(value: any, label: string = ""): OptionModel {
	return { value, label }
}

export const yesNoOptions: Array<OptionModel> = [
	toOptionModel(YesNo.YES, YesNo.YES),
	toOptionModel(YesNo.NO, YesNo.NO)
]

export interface ClickData {
	category: Mutation
	action: keyof typeof EventAction
	label: string
}

export interface ViewData {
	pagePath: string
	pageSubject: string
}

export interface DownloadForm {
	clauses: Array<ClauseCode>
	category: string
	title: string
	url: string
}

export interface PayoutForm {
	mutationType: MutationType
	subject: string
	title: string
	intro: string
	taskTypeRequest?: TaskType
	requiredDocuments?: Array<RequiredDocument>
	downloadForm?: PaperForm,
	paperFormList?: Array<DownloadForm>
	step1: string
	step2: string
	step3: string
}
